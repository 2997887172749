















































import { Component, Vue } from "vue-property-decorator";
import { ExKinkCategory, InKink, InKinkCategory } from "@/types/kinks";
import { getDefaultKinkContent, getDefaultRatings } from "./data/default";
import { Rating } from "./types/ratings";
import { generateKinklistImage } from "./util/generateImage";
import { downloadImageDirectly, uploadImageToImgur } from "./util/imageTransfer";
import { importDataFromImgur } from "./util/importFromImgur";

import { showDialog } from './components/Dialogs/dialog';

import Category from "./components/Category.vue";
import UploadResultDialog from "./components/Dialogs/UploadResultDialog.vue";
import PromptDialog from "./components/Dialogs/PromptDialog.vue";
import ErrorDialog from "./components/Dialogs/ErrorDialog.vue";
import AboutDialog from "./components/Dialogs/AboutDialog.vue";
import EditCategoryDialog from "./components/Dialogs/EditCategoryDialog.vue";
import ExportButton from "./components/ExportButton.vue";
import Importing from "./components/Importing.vue";
import Legend from "./components/Legend.vue";
import { generateId } from "./util/idGenerator";

@Component({
    components: {
        Category,
        ExportButton,
        Importing,
        Legend,
    },
})


export default class App extends Vue {
    ratings: Rating[] = [];
    categories: InKinkCategory[] = [];
    username = "";
    uploadId = "";
    uploading = false;
    importing = false;
    showOptions = false;
    // darkMode = false;
    encodeData = true;
    useImgur = false;
    numColumns = 4;

    public get uploadUrl(): string {
        return this.uploadId ? `https://i.imgur.com/${this.uploadId}.png` : '';
    }

    public get columns(): InKinkCategory[][] {
        const cols: InKinkCategory[][] = [];
        const headingHeight = 108;
        const rowHeight = 27;
        const totalHeight =
            this.categories.length * headingHeight +
            this.categories.map((c) => c.kinks).flat().length * rowHeight;

        // Iterate through categories and allocate to columns
        const avgColHeight = totalHeight / this.numColumns;
        let colHeight = 0;
        let col: InKinkCategory[] = [];
        cols.push(col);
        for (const cat of this.categories) {
            const catHeight = headingHeight + cat.kinks.length * rowHeight;
            if (colHeight + catHeight / 2 > avgColHeight) {
                col = [];
                cols.push(col);
                colHeight = 0;
            }
            col.push(cat);
            colHeight += catHeight;
        }
        return cols;
    }

    public userChoiceUpdated(): void {
        localStorage.setItem('userChoices', JSON.stringify(this.serializeChoices()));
        // save this.username
        localStorage.setItem('username', this.username);
    }

    public serializeChoices(): ExKinkCategory[] {
        // Serialize the current state of choices to match the ExKinkCategory and ExKink structure
        return this.categories.map((category) => ({
            name: category.name,
            subcategories: category.subcategories.slice(), // Assuming there are subcategories
            kinks: category.kinks.map((kink) => ({
                name: kink.name,
                ratings: kink.ratings,
                comment: kink.comment // Ensure this is optional as per the ExKink interface
            }))
        }));
    }

    public show(): void {
        function clickEffect() {
            let balls: Ball[] = [];
            let longPressed = false;
            let longPress: ReturnType<typeof setTimeout> | null = null;
            let multiplier = 0;
            let width: number | null = null;
            let height: number | null = null;
            let origin: { x: number; y: number } | null = null;
            let normal: { x: number; y: number } | null = null;
            let ctx: CanvasRenderingContext2D | null = null;
            const colours = ['#E0125E', '#F3BCC8', '#ACBF17', '#6DDDF2', '#E9C9F2'];
            const canvas = document.createElement('canvas');
            document.body.appendChild(canvas);
            canvas.setAttribute("style", "width: 100%; height: 100%; top: 0; left: 0; z-index: 99999; position: fixed; pointer-events: none;");
            const pointer = document.createElement('span');
            pointer.classList.add('pointer');
            document.body.appendChild(pointer);

            if (canvas.getContext && window.addEventListener) {
                ctx = canvas.getContext('2d');
                updateSize();
                window.addEventListener('resize', updateSize, false);
                loop();

                window.addEventListener('mousedown', (e) => {
                    pushBalls(randBetween(10, 20), e.clientX, e.clientY);
                    document.body.classList.add('is-pressed');
                    longPress = setTimeout(() => {
                        document.body.classList.add('is-longpress');
                        longPressed = true;
                    }, 500);
                }, false);
                window.addEventListener('mouseup', (e) => {
                    if (longPress !== null) {
                        clearInterval(longPress);
                        longPress = null;
                    }
                    if (longPressed) {
                        document.body.classList.remove('is-longpress');
                        pushBalls(randBetween(50 + Math.ceil(multiplier), 100 + Math.ceil(multiplier)), e.clientX, e.clientY);
                        longPressed = false;
                    }
                    document.body.classList.remove('is-pressed');
                }, false);

                window.addEventListener('mousemove', (e) => {
                    let x = e.clientX;
                    let y = e.clientY;
                    pointer.style.top = `${y}px`;
                    pointer.style.left = `${x}px`;
                }, false);
            }

            function updateSize() {
                if (canvas === null) return;
                canvas.width = window.innerWidth * 2;
                canvas.height = window.innerHeight * 2;
                canvas.style.width = `${window.innerWidth}px`;
                canvas.style.height = `${window.innerHeight}px`;
                ctx?.scale(2, 2);
                width = canvas.width = window.innerWidth;
                height = canvas.height = window.innerHeight;
                origin = {
                    x: width ? width / 2 : 0,
                    y: height ? height / 2 : 0,
                };
                normal = {
                    x: width ? width / 2 : 0,
                    y: height ? height / 2 : 0,
                };
            }

            class Ball {
                public x: number;
                public y: number;
                public angle: number;
                public multiplier: number;
                public vx: number;
                public vy: number;
                public r: number;
                public color: string;

                constructor(x: number = origin?.x ?? 0, y: number = origin?.y ?? 0) {
                    this.x = x;
                    this.y = y;
                    this.angle = Math.PI * 2 * Math.random();
                    if (longPressed) {
                        this.multiplier = randBetween(14 + multiplier, 15 + multiplier);
                    } else {
                        this.multiplier = randBetween(6, 12);
                    }
                    this.vx = (this.multiplier + Math.random() * 0.5) * Math.cos(this.angle);
                    this.vy = (this.multiplier + Math.random() * 0.5) * Math.sin(this.angle);
                    this.r = randBetween(8, 12) + 3 * Math.random();
                    this.color = colours[Math.floor(Math.random() * colours.length)];
                }

                public update() {
                    this.x += this.vx - (normal?.x ?? 0);
                    this.y += this.vy - (normal?.y ?? 0);
                    if (normal && normal.x != null && normal.y != null) {
                        normal.x = -2 / window.innerWidth * Math.sin(this.angle);
                        normal.y = -2 / window.innerHeight * Math.cos(this.angle);
                    }
                    this.r -= 0.3;
                    this.vx *= 0.9;
                    this.vy *= 0.9;
                }
            }

            function pushBalls(count = 1, x: number = origin?.x ?? 0, y: number = origin?.y ?? 0) {
                for (let i = 0; i < count; i++) {
                    balls.push(new Ball(x, y));
                }
            }

            function randBetween(min: number, max: number): number {
                return Math.floor(Math.random() * max) + min;
            }

            function loop() {
                if (ctx === null || canvas === null) return;
                ctx.fillStyle = 'rgba(255, 255, 255, 0)';
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                for (let i = 0; i < balls.length; i++) {
                    let b = balls[i];
                    if (b.r < 0) continue;
                    ctx.fillStyle = b.color;
                    ctx.beginPath();
                    ctx.arc(b.x, b.y, b.r, 0, Math.PI * 2, false);
                    ctx.fill();
                    b.update();
                }
                if (longPressed) {
                    multiplier += 0.2;
                } else if (!longPressed && multiplier >= 0) {
                    multiplier -= 0.4;
                }
                removeBall();
                requestAnimationFrame(loop);
            }

            function removeBall() {
                for (let i = 0; i < balls.length; i++) {
                    let b = balls[i];
                    if (
                        b.x + b.r < 0 ||
                        b.x - b.r > (width != null ? width : 0) ||
                        b.y + b.r < 0 ||
                        b.y - b.r > (height != null ? height : 0) ||
                        b.r < 0
                    ) {
                        balls.splice(i, 1);
                    }
                }
            }
        }

        clickEffect();
    }


    mounted(): void {
        document.title = '綾香のKinklist';
        // this.note()
        this.checkFirstVisit();
        this.show()
    }

    checkFirstVisit(): void {
        const hasVisited = localStorage.getItem('hasVisited');
        if (!hasVisited) {
            this.showAbout();
            localStorage.setItem('hasVisited', 'true');
        }
    }

    public async created(): Promise<void> {
        if (!(await this.tryLoadImgurData())) {
            this.loadDefaults();
        }
        this.updateNumColumns();
        const storedChoices = localStorage.getItem('userChoices');
        if (storedChoices) {
            const choices: ExKinkCategory[] = JSON.parse(storedChoices);
            this.initializeChoices(choices);
            this.username = localStorage.getItem('username') || this.username;
        }
        window.addEventListener('resize', () => {
            this.updateNumColumns();
        });
    }

    public initializeChoices(loadedChoices: ExKinkCategory[]): void {
        // Assume this.categories is already initialized with default data including ids.
        this.categories.forEach((category) => {
            // Find the matching category by name from the loaded choices.
            const loadedCategory = loadedChoices.find((c) => c.name === category.name);
            if (loadedCategory) {
                category.kinks.forEach((kink) => {
                    // Find the matching kink by name from the loaded category.
                    const loadedKink = loadedCategory.kinks.find((k) => k.name === kink.name);
                    if (loadedKink) {
                        // Update the ratings of the kink from the loaded data.
                        kink.ratings = loadedKink.ratings;
                    }
                });
            }
        });
        this.updateRatings(this.ratings);
    }

    public updateNumColumns(): void {
        const screenWidth = Math.min(window.innerWidth, 1740);
        this.numColumns = Math.max(1, Math.floor(screenWidth / 400));
    }

    public async exportImage(): Promise<void> {
        try {
            this.uploading = true;
            const canvas = generateKinklistImage(this.categories, this.ratings, this.username, this.encodeData);
            if (this.useImgur) {
                const id = await uploadImageToImgur(canvas);
                const hasAnyComment = this.categories.some((c) => c.kinks.some((k) => k.comment));
                showDialog(UploadResultDialog, {
                    uploadId: id, hasEncodedData: this.encodeData && hasAnyComment
                });
            } else {
                downloadImageDirectly(canvas, "kinklist.png");
            }


        } catch (ex) {
            showDialog(ErrorDialog, { message: "Something went wrong uploading the image" });
            console.error("Something went wrong uploading kinklist");
            console.error(ex);
        }
        this.uploading = false;
    }

    public clearLocalStorage(): void {
        localStorage.clear();
        this.loadDefaults(); // Implement this method as needed
    }

    public showAbout(): void {
        this.toggleOptions(false);
        showDialog(AboutDialog, {});
    }

    public async addCategory(): Promise<void> {
        type CategoryModalResult = false | Pick<InKinkCategory, "name" | "subcategories">;
        const result: CategoryModalResult = await showDialog(EditCategoryDialog, {});
        if (!result) return;

        this.categories.push({
            id: generateId(),
            name: result.name,
            subcategories: result.subcategories,
            kinks: [{
                id: generateId(),
                name: 'Example kink',
                ratings: {
                    General: this.ratings[0].name,
                },
            }],
        });
    }

    public removeCategory(category: InKinkCategory): void {
        this.categories = this.categories.filter(c => c != category);
    }

    public async addKink(category: InKinkCategory): Promise<void> {
        const newKinkName: false | string = await showDialog(PromptDialog, {
            title: 'Add kink',
            inputLabel: 'Kink name:',
            value: '',
        });
        if (newKinkName) {
            category.kinks.push({
                id: generateId(),
                name: newKinkName,
                ratings: category.subcategories.reduce((map: Record<string, string>, rating: string): Record<string, string> => {
                    return { ...map, [rating]: this.ratings[0].name };
                }, {}),
            });
        }
    }

    public removeKink(category: InKinkCategory, kink: InKink): void {
        category.kinks = category.kinks.filter(ck => ck.id !== kink.id);
    }

    public updateRatings(newRatings: Rating[]): void {
        type KinkRatings = Record<string, string>;
        this.ratings = newRatings.map((r) => ({ ...r }));
        for (const category of this.categories) {
            for (const kink of category.kinks) {
                kink.ratings = category.subcategories.reduce((ratings: KinkRatings, subcategory): KinkRatings => {
                    const rating = newRatings.some(nr => nr.name === kink.ratings[subcategory])
                        ? kink.ratings[subcategory]
                        : newRatings[0].name;
                    return {
                        ...ratings,
                        [subcategory]: rating,
                    };
                }, {});
            }
        }
    }

    public toggleOptions(newValue: boolean): void {
        this.showOptions = newValue;
        if (newValue) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }

    private async tryLoadImgurData(): Promise<boolean> {
        // Get the hash
        const id = this.getImgurHash();
        // If there is no hash, no download happens
        if (!id) return false;

        try {
            // Download image
            this.importing = true;
            const { categories, ratings, username } = await importDataFromImgur(id);
            this.username = username
            this.categories = categories;
            this.ratings = ratings;
            this.importing = false;
            return true;
        } catch (ex) {
            showDialog(ErrorDialog, { message: "Something went wrong parsing loading kinklist data" });
            console.error("Something went wrong downloading/parsing kinklist");
            console.error(ex);
            this.importing = false;
            return false;
        }
    }

    private getImgurHash(): false | string {
        if (!location.hash) return false;
        if (location.hash.length <= 1) return false;
        if (!location.hash.match(/^#[a-zA-Z0-9]{3,10}$/)) return false;
        const id = location.hash.substr(1);
        return id;
    }

    private loadDefaults(): void {
        this.username = "";
        this.ratings = getDefaultRatings();
        this.categories = getDefaultKinkContent(this.ratings[0].name);
    }
}
