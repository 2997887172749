import {generateId} from '@/util/idGenerator';
import {InKinkCategory} from '../types/kinks';
import {Rating} from '../types/ratings';

export const getDefaultRatings = (): Rating[] => [
    {name: '未入力 None', color: '#FFF'},
    {name: 'お気に入り❤ Favorite', color: '#F74A8B'},
    {name: '好き！Like', color: '#7CCC21'},
    {name: 'まあまあーOkay', color: '#F2CA99'},
    {name: '多分～Maybe', color: '#DB6C00'},
    {name: 'いいえ～No', color: '#000000'},
];

const createKinkCategory = (name: string, subcategories: string[], kinks: string[], defaultRating: string): InKinkCategory => ({
    id: generateId(),
    name,
    subcategories,
    kinks: kinks.map((kinkname) => ({
        id: generateId(),
        name: kinkname,
        ratings: subcategories.reduce((map: Record<string, string>, subcategory: string) => ({...map, [subcategory]: defaultRating}), {}),
    })),
});

export const getDefaultKinkContent = (defaultRating: string): InKinkCategory[] => [
    createKinkCategory("体(Bodies)", ["Self", "Partner"], ["やせ型(Skinny)👦🏼/👧🏻", "ぽっちゃり(Chubby)👦🏼/👧🏻", "小さい胸(Small breasts)👧🏻", "大きい胸(Large breasts)👧🏻", "綾香型(Like Ayaka) 156cm/44kg👧🏻", "小さいペニス(Small cocks)👦🏼", "大きいペニス(Large cocks)👦🏼"], defaultRating),
    createKinkCategory("服装(Clothing)", ["Self", "Partner"], ["着衣セックス(Clothed sex)👦🏼/👧🏻", "ランジェリー(Lingerie)👦🏼/👧🏻", "ストッキング(Stockings)👧🏻", "ヒール(Heels)👧🏻", "レザー(Leather)👦🏼/👧🏻", "ラテックス(Latex)👦🏼/👧🏻", "ユニフォーム(Uniform)👦🏼/👧🏻", "浴衣(Yukata)👦🏼/👧🏻"], defaultRating),
    createKinkCategory("人種(Partner's race)", ["Partner"], ["アジア人(Asian)👦🏻/👧🏻", "ヨーロッパ人(European)👦🏼/👩🏼", "アフリカ人(African)👦🏿/👩🏿", "ラテン人(Latino)👦🏾/👩🏾", "インド人(Indian)👦🏽/👩🏽"], defaultRating),
    createKinkCategory("グルーピング(Groupings)", ["General"], ["と1人の男性(w/ 1 male)", "と1人の女性(w/ 1 female)", "とトランス女性(w/ trans female)", "とトランス男性(w/ trans male)", "と男性&女性(w/ 1 male 1 female)", "と2人の男性(w/ 2 males)", "と2人の女性(w/ 2 females)", "乱交(Orgy)"], defaultRating),
    createKinkCategory("一般(General)", ["Giving", "Receiving"], ["ロマンス/愛情(Romance / Affection)", "手コキ/指マン(Handjob / fingering)", "フェラチオ(Blowjob)", "ディープスロート(Deep throat)", "飲み込む(Swallowing)", "顔射(Facials)", "顔面騎乗(Face-sitting)", "エッジング(Edging)", "いじめ(Teasing)"], defaultRating),
    createKinkCategory("アナルプレイ(Ass play)", ["Giving", "Receiving"], ["アナルトイ(Anal toys)", "アナルセックス(Anal sex)", "アナル舐め(Rimming)", "二穴(Double penetration)", "アナルフィスト(Anal fisting)"], defaultRating),
    createKinkCategory("制限(Restrictive)", ["Giving", "Receiving"], ["ギャグ(Gag)", "首輪(Collar)", "リード(Leash)", "貞操(Chastity)", "軽いボンデージ(Light Bondage)", "重いボンデージ(Heavy Bondage)"], defaultRating),
    createKinkCategory("おもちゃ(Toys)", ["Giving", "Receiving"], ["ディルド(Dildos)", "プラグ(Plugs)", "バイブレーター(Vibrators)", "ねこみみ(Cat ears)"], defaultRating),
    createKinkCategory("支配(Domination)", ["Dominant", "Submissive"], ["支配/服従(Dominant / Submissive)", "家庭内奉仕(Domestic servitude)", "奴隷(Slavery)", "ペットプレイ(Pet play)", "おねだり(Begging)", "強制絶頂(Forced orgasm)", "絶頂禁止(Orgasm denial)"], defaultRating),
    createKinkCategory("非合意(No consent)", ["Aggressor", "Target"], ["強姦(Non-con / rape)", "脅迫/強要(Blackmail / coercion)", "誘拐(Kidnapping)", "キメセク(Drugged sex)", "アルコール(Alcohol)", "スリーププレイ(Sleep play)"], defaultRating),
    createKinkCategory("タブー(Taboo)", ["General"], ["近親相姦(Incest)", "年の差(Age gap)", "レースプレイ(Raceplay)", "獣姦(Bestiality)", "死姦(Necrophilia)", "浮気(Cheating)", "露出症(Exhibitionism)", "盗撮(Voyeurism)", "ショタおね(Sister with cute boy)"], defaultRating),
    createKinkCategory("シュール(Surrealism)", ["Self", "Partner"], ["ふたなり(Futanari)", "ファーリー(Furry)", "ゾンビ(Zombie)", "触手(Tentacles)", "ゴブリン(Goblin)"], defaultRating),
    createKinkCategory("ポーズ(Sex Pose)", ["Partner"], ["駅弁ファック(Ekiben/Carrying)", "背面駅弁(Back carrying)", "フルネルソン(Fullnelson)", "種付けプレス(Mating press)", "キスハメ(Kiss&Sex)", "対面座位(Face-to-face sitting)", "バック(Doggy)"], defaultRating),
    createKinkCategory("液体(Fluids)", ["General"], ["血(Blood)", "尿(Urine)", "授乳(Lactation)", "おむつ(Diapers)", "精液遊び(Cum play)"], defaultRating),
    createKinkCategory("屈辱(Degradation)", ["Giving", "Receiving"], ["グローリーホール(Glory hole)", "肉便器(Cum dump)", "屈辱(Humiliation)", "身体に落書き(Body Writing)", "アヘ顔(Ahegao)"], defaultRating),
    createKinkCategory("フェチ(Fetish)", ["Giving", "Receiving"], ["フィストファック(Fisting)", "輪姦(Gangbang)", "呼吸制御(Breath play)", "中出し(Creampie)", "膣内放尿(Pee inside pussy)", "妊娠(Pregnancy)", "女装(Feminization)", "寝取り(NTR/Cuckold)"], defaultRating),
    createKinkCategory("痛み(Pain)", ["Giving", "Receiving"], ["軽い痛み(Light pain)", "重い痛み(Heavy pain)", "乳首クランプ(Nipple clamps)", "鞭打ち(Flogging)", "打撃(Beating)", "スパンキング(Spanking)", "熱いワックス(Hot Wax)", "引っかき(Scratching)", "噛みつき(Biting)", "切りつけ(Cutting)"], defaultRating),
]