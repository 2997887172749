



































import {InKinkCategory} from "@/types/kinks";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

import Dialog from "./Dialog.vue";

@Component({
    components: {
        Dialog,
    },
})
export default class EditCategoryDialog extends Vue {
    @Prop() category!: InKinkCategory;

    categoryName = '';
    subcategories = ['General'];

    get title(): string {
        return this.category ? 'Edit category' : 'New category';
    }

    @Watch('category', {immediate: true})
    updateModel(): void {
        if (!this.category) {
            this.categoryName = '';
            this.subcategories = ['General'];
        } else {
            this.categoryName = this.category.name;
            this.subcategories = this.category.subcategories.slice();
        }
    }

    addSubcategory(): void {
        this.subcategories.push('');
    }

    removeSubcategory(index: number): void {
        this.subcategories.splice(index, 1);
    }

    close(): void {
        this.$emit('close', false);
    }

    ok(): void {
        this.$emit('close', {
            name: this.categoryName,
            subcategories: this.subcategories,
        });
    }

    mounted(): void {
        (this.$refs.input as HTMLTextAreaElement)?.focus();
    }
}
