






















































import {InKink, InKinkCategory} from "@/types/kinks";
import {Rating} from "@/types/ratings";
import {Component, Prop, Vue} from "vue-property-decorator";
import {showDialog} from "./Dialogs/dialog";
import CommentDialog from "./Dialogs/CommentDialog.vue";
import PromptDialog from "./Dialogs/PromptDialog.vue";

@Component
export default class Kink extends Vue {
    @Prop() kink!: InKink;
    @Prop() category!: InKinkCategory;
    @Prop() ratings!: Rating[];

    async edit(): Promise<void> {
        const result: false | string = await showDialog(PromptDialog, {
            title: 'Edit kink',
            inputLabel: 'Kink name:',
            value: this.kink.name,
        });
        if (result !== false) {
            if (!result) this.remove();
            else this.kink.name = result;
        }
    }

    handleRatingSelection(): void {
        // Code to handle the user's rating selection
        // For example, emitting an event to the parent component
        this.$emit('userChoiceUpdated');
    }

    remove(): void {
        this.$emit("remove");
    }

    async addComment(): Promise<void> {
        await showDialog(CommentDialog, {category: this.category, kink: this.kink});
        if (!this.kink.comment?.trim()) {
            this.removeComment();
        }
    }

    removeComment(): void {
        this.$set(this.kink, "comment", undefined);
    }
}
