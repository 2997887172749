export const downloadImageDirectly = (canvas: HTMLCanvasElement, fileName: string): void => {
    const link = document.createElement('a');
    link.download = fileName;
    link.href = canvas.toDataURL();
    link.click();
};


const IMGUR_CLIENT_ID = '9db53e5936cd02f';
export const uploadImageToImgur = async (canvas: HTMLCanvasElement): Promise<string> => {
    const formdata = new FormData();
    formdata.append('image', canvas.toDataURL().split(',')[1]);
    formdata.append('type', 'base64');
    const response = await fetch(`https://api.imgur.com/3/image`, {
        method: 'POST',
        headers: {
            Authorization: 'Client-ID ' + IMGUR_CLIENT_ID,
        },
        body: formdata
    });
    const json = await response.json();
    if (!response.ok) {
        if (json.data.error) throw json.data.error;
        console.error({response, json});
        throw new Error('Imgur response: Not OK');
    }
    return json.data.id;
}

const POSTIMAGES_CLIENT_ID = 'a40c4be8cb7668272530996c6665f1a8';
// export const uploadImageToPostimages = async (canvas: HTMLCanvasElement): Promise<string> => {
// const formdata = new FormData();
// formdata.append('image', canvas.toDataURL().split(',')[1]);
// formdata.append('type', 'base64');
// const response = await fetch(`https://api.imgur.com/3/image`, {
//     method: 'POST',
//     headers: {
//         Authorization: 'Client-ID ' + IMGUR_CLIENT_ID,
//     },
//     body: formdata
// });
// const json = await response.json();
// if (!response.ok) {
//     if (json.data.error) throw json.data.error;
//     console.error({response, json});
//     throw new Error('Imgur response: Not OK');
// }
// return json.data.id;
// }